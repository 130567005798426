import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';
import 'assets/scss/style.scss';
import { information } from 'services/auth'; // Certifique-se de que o caminho está correto
import { jwtVerify } from 'jose';

const JWT_SECRET = new TextEncoder().encode('u3NZkIEuaoYA4XvYBPeDWlQCFygTeABX20OjYLl9ReLrmQRSxMgcgWpWgcxRsvks');
const token = localStorage.getItem('token');

const verifyToken = async (token, secret) => {
  try {
    const { payload } = await jwtVerify(token, secret);
    return payload;
  } catch (err) {
    throw err;
  }
};

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
};

const handleToken = async () => {
  if (!token) {
    console.log('No token found. Rendering without token.');
    renderApp();
    return;
  }

  console.log('Token found:', token);

  if (!window.crypto || !window.crypto.subtle) {
    console.error('Crypto.subtle is not available in this environment. Rendering without token.');
    await loadUserInformation();
    renderApp();
    return;
  }

  try {
    const decoded = await verifyToken(token, JWT_SECRET);
    console.log('Decoded token:', decoded);
    await loadUserInformation();
    renderApp();
  } catch (err) {
    console.error('JWT verification error:', err);
    handleTokenError(err);
    renderApp();
  }
};

const loadUserInformation = async () => {
  const unit = parseInt(localStorage.getItem('unit'));
  const res = await information();
  store.dispatch({ type: 'SET_LOGIN', payload: res.data });
  store.dispatch({ type: 'SET_UNIT_USER', payload: unit });
};

const handleTokenError = (err) => {
  if (err.code === 'ERR_JWT_EXPIRED') {
    console.log('Token expired. Removing token from localStorage.');
    localStorage.removeItem('token');
  } else if (err.code === 'ERR_JWT_MALFORMED' || err.code === 'ERR_JWS_INVALID') {
    console.log('Invalid token. Removing token from localStorage.');
    localStorage.removeItem('token');
  } else {
    console.log('Token verification failed with error:', err);
  }
};

handleToken();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
