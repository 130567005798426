import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CardActionArea } from '@mui/material';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
    })
}));

export default function RecipeReviewCard(props) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card
            style={{
                boxShadow: '0px 9px 18px rgba(0, 0, 0, 0.18)',
                overflow: 'visible',
                maxWidth: 370,
                margin: 2
            }}
            // sx={{ width: '100%' }}
        >
            <CardActionArea onClick={() => props.navigate({ pathname: `/${props.route}` })}>
                <CardHeader
                    // avatar={
                    //     props.atendimento === 1 || props.solucao === 1 ? (
                    //         <Avatar sx={{ color: '#FFF', bgcolor: red[500] }} aria-label="recipe">
                    //             !
                    //         </Avatar>
                    //     ) : (
                    //         ''
                    //     )
                    // }
                    action={props.status}
                    title={props.title}
                    subheader={props.created}
                />
                <Typography style={{ marginLeft: '8%', marginTop: '-20px' }} height={40} fontSize={12}>
                    <b>{props.nature}</b>
                </Typography>
                <CardContent>
                    <Typography variant="body2" style={{ color: 'black' }}>
                        {props.contentMain}
                    </Typography>
                </CardContent>
                {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>{props.contentSecondary}</CardContent>
                </Collapse> */}
            </CardActionArea>
            <CardActions disableSpacing>
                {props.buttonCancel}
                {/* <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton> */}
                {/* <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                    <ExpandMoreIcon />
                </ExpandMore> */}
            </CardActions>
        </Card>
    );
}
