// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import menuTechnical from 'menu-items/menuTechnical';
import { useSelector } from 'react-redux';
import menuManager from 'menu-items/menuManager';
import menuSupervisor from 'menu-items/menuSupervisor';
import menuVi from 'menu-items/meuVi';

// ==============================|| SIDEBAR MENU LIST ||============================== //
const MenuList = () => {
    const id_role = useSelector((state) => state.auth.user.perfil_id);
    const supervisor = useSelector((state) => state.auth.user.supervisor);
    let nav = '';
    if (id_role === 3) {
        if (supervisor == 1) {
            nav = menuSupervisor;
        } else {
            nav = menuTechnical;
        }
    } else if (id_role === 4) {
        nav = menuManager;
    }else if (id_role === 7) {
        nav = menuVi;
    } else {
        nav = menuItem;
    }
    const navItems = nav.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
